// eslint-disable-next-line no-redeclare,no-unused-vars
function buildFilePopup(param) {
  var src;
  var doc = {};

  if (param.src) src = param.src;
  else if (param.id) {
    doc = getData('document', param.id, null, null, function () {
      if (!param.second) buildFilePopup(Object.assign(param, {second: true}));
    });
    if (!doc) return;
    src = doc.file;
  }
  if (!src) return;

  if (!doc.type) doc.type = src.slice(src.lastIndexOf('.') + 1);
  if (mobile && doc.type == 'pdf') return download(src);

  // popup
  var pm = {
    title: doc.title,
    bodyCard: true,
    close: param.close,
    buttons: {},
  };
  if (param.sign) pm.buttons['Dokument unterschreiben'] = function () {};
  pm.buttons['Dokument herunterladen'] = function () {
    download(doc);
  };
  if (param.buttons) pm.buttons = Object.assign(pm.buttons, param.buttons);
  var popup = buildPopup(pm);
  popup.addClass('modal-file');

  // content
  requestIdleCallback(function () {
    if (doc.type == 'image')
      $('<img class="w-100" src="' + src + '">').appendTo(popup.body);
    else if (doc.type == 'pdf')
      $(
        '<object class="w-100" data="' +
          src +
          '" type="application/pdf"></object>'
      ).appendTo(popup.body);
    else if (doc.content) popup.body.append(doc.content);
    else
      $(
        '<object class="w-100" data="' + src + '" type="text/plain"></object>'
      ).appendTo(popup.body);
  });
}
